import { Socket } from 'Src/models/socket';
import { log as baseLogger } from 'Src/utils/log';

import * as types from './types';

const sectionId = 'NotificationProcessor';
const log = baseLogger.getSubLogger({ name: sectionId });

/**
 * Add more POS notification events here as we define them
 */
declare global {
  export interface GlobalEventHandlersEventMap {
    'notify.AuthorizationRequestAnswered': types.CustomNotificationEvent<types.AuthorizationRequestAnswered>;
    'notify.AuthorizationRequestCreated': types.CustomNotificationEvent<types.AuthorizationRequestCreated>;
    'notify.CheckCreated': types.CustomNotificationEvent<types.CheckHeaderCreated>;
    'notify.CheckPrintJobActionTaken': types.CustomNotificationEvent<types.CheckPrintJobActionTakenNotification>;
    'notify.CheckPrintJobResult': types.CustomNotificationEvent<types.CheckPrintJobResultNotification>;
    'notify.CheckUpdated': types.CustomNotificationEvent<types.CheckHeaderUpdated>;
    'notify.DebugLogsEngage': types.CustomNotificationEvent<types.DebugLogsEngage>;
    'notify.FloorplanUpserted': types.CustomNotificationEvent<types.FloorplanUpserted>;
    'notify.PrinterInfoChanged': types.CustomNotificationEvent<types.PrinterInfoChanged>;
    'notify.TerminalReleaseNotification': types.CustomNotificationEvent<types.TerminalRelease>;
    'notify.EODTaskCompleted': types.CustomNotificationEvent<types.EODTaskCompleted>;
    'notify.EODTaskFailed': types.CustomNotificationEvent<types.EODTaskFailed>;
    'notify.QuantityUpdate': types.CustomNotificationEvent<types.QuantityUpdate>;
    'notify.EntityCUD.Updated.EmployeeShift': types.CustomNotificationEvent<types.EntityCUDUpdatedEmployeeShiftNotification>;
    'notify.S2P_CheckPaid': types.CustomNotificationEvent<types.S2PCheckPaid>;
  }
}

/**
 * NotificationProcessor receives generic socket notifications from the server
 * and relays them out as typed notifications that have specific
 * purpose or meaning for the app via DOM events.
 */
class NotificationProcessor {
  constructor(s: Socket) {
    s.on('notification', (n) => {
      if (!this.handleEvent(n as types.Notifications)) {
        log.debug('received notification from server, but it is of unknown type: {@n}', n);
      }
    });
  }

  /**
   * Parse out the type of notification and emit/broadcast
   * @param n the generic notification
   * @returns true if it was emitted, false if we can't figure out what it is
   */
  private handleEvent(n: types.Notifications): boolean {
    let evtType: keyof GlobalEventHandlersEventMap | null = null;
    const evtData = n.data;

    switch (n.type) {
      case 'AuthorizationRequestAnswered': evtType = 'notify.AuthorizationRequestAnswered'; break;
      case 'AuthorizationRequestCreated': evtType = 'notify.AuthorizationRequestCreated'; break;
      case 'CheckCreated': evtType = 'notify.CheckCreated'; break;
      case 'CheckPrintJobActionTaken': evtType = 'notify.CheckPrintJobActionTaken'; break;
      case 'CheckPrintJobResult': evtType = 'notify.CheckPrintJobResult'; break;
      case 'CheckUpdated': evtType = 'notify.CheckUpdated'; break;
      case 'DebugLogsEngage': evtType = 'notify.DebugLogsEngage'; break;
      case 'FloorplanUpserted': evtType = 'notify.FloorplanUpserted'; break;
      case 'PrinterInfoConfigChange': evtType = 'notify.PrinterInfoChanged'; break;
      case 'TerminalReleaseNotification': evtType = 'notify.TerminalReleaseNotification'; break;
      case 'EODTaskFailed': evtType = 'notify.EODTaskFailed'; break;
      case 'EODTaskCompleted': evtType = 'notify.EODTaskCompleted'; break;
      case 'QuantityUpdate': evtType = 'notify.QuantityUpdate'; break;
      case 'S2P_CheckPaid': evtType = 'notify.S2P_CheckPaid'; break;
      case 'EntityCUD': {
        if (n.data.action === 'Updated' && n.data.type === 'EmployeeShift') evtType = 'notify.EntityCUD.Updated.EmployeeShift';
      }
    }
    if (evtType) {
      log.info('handleEvent()', evtType);
      document.dispatchEvent(new CustomEvent(evtType, { detail: evtData }));
      return true;
    }
    return false;
  }
}

export default NotificationProcessor;
