import React, { FC } from 'react';

import { ToastAutoHideDuration } from 'Src/consts/duration';
import { useCheck } from 'Src/feature/check/useCheck';
import { useCheckHeader } from 'Src/feature/check/useCheckHeader';
import { useToast } from 'Src/feature/toasts/useToast';
import useDOMEventListener from 'Src/utils/useDOMEvtListener';

import { AuthRequestToastNotification } from './AuthRequestToastNotification';
import { PrintFailureToastNotification } from './PrintFailureToastNotification';
import { S2PCheckPaidToastNotification } from './S2PCheckPaidToastNotification';
import * as types from './types';
import { useIsMyNotification } from './useIsMyNotification';
import { NotificationUI, useNotification } from './useNotification';

const sectionId = 'NotificationListener';
const NotificationListener: FC = () => {

  const toast = useToast();
  const { add: addNotification, remove: removeNotification } = useNotification();
  const { isMyNotification } = useIsMyNotification();
  const checkInvalidate = useCheck.query.invalidate();
  const checkHeaderInvalidate = useCheckHeader.query.invalidate();

  useDOMEventListener('notify.AuthorizationRequestCreated', (evt) => {
    const newNotification = new NotificationUI<types.AuthorizationRequestCreated>({
      type: 'authorizationRequest',
      id: evt.detail.id?.toString() as string,
      time: Date.now(),
      data: evt.detail,
    });

    addNotification(newNotification);

    if (isMyNotification(newNotification)) {
      toast.make({
        // sharing the same id, so we only show the last toast
        id: `${sectionId}.auth.request`,
        autoHideDuration: ToastAutoHideDuration.Notification,
        type: 'custom',
        render: (t) => (
          <AuthRequestToastNotification
            notification={newNotification}
            onAction={() => toast.store?.hide(t)}
          />
        )
      }).show();
    }
  });

  useDOMEventListener('notify.AuthorizationRequestAnswered', (evt) => {
    removeNotification(evt.detail.id?.toString() as string);
  });

  useDOMEventListener('notify.CheckPrintJobResult', (evt) => {
    // task 1: invalidate the check cache
    // NOTE: CheckPrintJobResult messages from the server indicate that the check has been updated because
    //       a print job status has changed. In that case we need to update the check.
    //       BUT.... we don't need to update the check header since print job statuses are not in the check header.
    //       Therefore we don't do a fetch of the check and a check "sync" ... we instead just invalidate the check data.
    checkInvalidate.getOne(evt.detail.checkId);

    // task 2: make a notification if there was a print error
    //         everything below here is part of task 2
    // we only want to handle error states
    if (!evt.detail.errStr) return;

    const newNotification = new NotificationUI<types.CheckPrintJobFailure>({
      type: 'printFailure',
      // eslint-disable-next-line @typescript-eslint/no-base-to-string
      id: evt.detail.id?.toString(),
      time: Date.now(),
      data: evt.detail,
    });

    addNotification(newNotification);

    if (isMyNotification(newNotification)) {
      toast.make({
        // sharing the same id, so we only show the last toast
        id: `${sectionId}.print.failure`,
        autoHideDuration: ToastAutoHideDuration.Notification,
        type: 'custom',
        render: (t) => (
          <PrintFailureToastNotification
            notification={newNotification}
            onAction={() => toast.store?.hide(t)}
          />
        )
      }).show();
    }
  });

  useDOMEventListener('notify.CheckPrintJobActionTaken', (evt) => {
    evt.detail?.jobUids?.forEach((jobId) => removeNotification(jobId));
  });

  useDOMEventListener('notify.PrinterInfoChanged', (evt) => {
    if (!evt.detail.printer?.id) return;
    // always remove the current status
    removeNotification(evt.detail.printer.id.toString());
    // only put a status into notifications if that status is not good
    if (evt.detail.printer.status !== 'Ready') addNotification(new NotificationUI<types.PrinterInfoChanged>({
      type: 'printerStatus',
      id: evt.detail.printer.id.toString(),
      time: Date.now(),
      data: evt.detail,
    }));
  });

  useDOMEventListener('notify.S2P_CheckPaid', (evt) => {
    // task 1: invalidate the check cache
    // NOTE: S2P_CheckPaid messages from the server indicate that the check has been updated because
    //       the check has been paid in full and was closed. In that case we need to update the check.
    //       We also need to update the check header because, while payments do not live on the
    //       check header, the overall check status does.
    checkInvalidate.getOne(evt.detail.check.id);
    checkInvalidate.getAll();
    checkHeaderInvalidate.all();

    // task 2: make a notification
    const newNotification = new NotificationUI<types.S2PCheckPaid>({
      type: 's2pCheckPaid',
      id: evt.detail.check?.id?.toString(),
      time: Date.now(),
      data: evt.detail,
    });
    addNotification(newNotification);
    if (isMyNotification(newNotification)) {
      toast.make({
        id: `${sectionId}.check.paid.${evt.detail.check?.id ?? ''}`,
        autoHideDuration: ToastAutoHideDuration.Notification,
        type: 'custom',
        render: () => (
          <S2PCheckPaidToastNotification notification={newNotification} />
        ),
      }).show();
    }
  });

  return null;
};

export { NotificationListener, sectionId };
