import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Copy from 'Src/components/Copy';
import { useModalControl } from 'Src/components/modals/useModalControl';
import { Modal } from 'Src/feature/modal/Modal';
import { ModalContent } from 'Src/feature/modal/ModalContent';
import { ModalHeader } from 'Src/feature/modal/ModalHeader';
import { ModalTitle } from 'Src/feature/modal/ModalTitle';
import { useCurrentOperator } from 'Src/feature/operator/useCurrentOperator';
import useDOMEventListener from 'Src/utils/useDOMEvtListener';

import styles from './RemoteClockoutModal.module.scss';

const RemoteClockoutModal: FC = () => {
  const { t } = useTranslation();
  const { currentOperator } = useCurrentOperator();

  const modal = useModalControl({
    onOpen: () => setTimeout(() => window.location.reload(), 5000),
  });

  useDOMEventListener('notify.EntityCUD.Updated.EmployeeShift', (evt) => {
    if (!!evt.detail.entity.clockOut && evt.detail.entity.employeeId === currentOperator?.employee.id) modal.open();
  });

  return (
    <Modal
      open={modal.isOpen}
      variant="grow"
    >
      <ModalHeader>
        <ModalTitle copy={t('remoteClockout.labels.title')} />
      </ModalHeader>
      <ModalContent className={styles.content}>
        <Copy value={t('remoteClockout.labels.message')} />
      </ModalContent>
    </Modal>
  );
};

export { RemoteClockoutModal };