import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Copy from 'Src/components/Copy';

import { S2PCheckPaid } from './types';
import { NotificationUI } from './useNotification';

import styles from './S2PCheckPaidToastNotification.module.scss';

const sectionId = 'S2PCheckPaidToastNotification';

interface S2PCheckPaidToastNotificationProps {
  notification: NotificationUI<S2PCheckPaid>;
}

const S2PCheckPaidToastNotification: FC<S2PCheckPaidToastNotificationProps> = ({ notification }) => {
  const { t } = useTranslation();
  const { data } = notification;

  return (
    <div data-testid={sectionId} className={styles.root}>
      <Copy color="textPrimary" className={styles.message} value={t('notification.s2pCheckPaid', { checkLabel: data.check.label })} />
    </div>
  );
};

export { S2PCheckPaidToastNotification };
